import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "reactstrap"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import {
  BannerHomepage,
  Link,
  PaddingWrapper,
  ReferencesFull,
  TextBoxImageTop,
  TitleH2Center,
  Button,
  ButtonStyle,
} from "@igloonet-web/shared-ui"

import BadgeGuru from "../images/HP/badge-guru.png"
import BadgePartner from "../images/HP/badge-partner.png"
import BadgeSklik from "../images/HP/badge-sklik.png"
import BadgeTrainer from "../images/HP/badge-trainer.png"
import HeurekaPartner from "../images/HP/heureka-partner.png"

import IconNetwork from "../icons/network.svg"
import IconResponsive from "../icons/responsive.svg"
import IconWebpage from "../icons/webpage.svg"

import LogoDoktorKladivo from "../images/reference/doktor-kladivo/logo-doktorkladivo.png"
import LogoFSIVUT from "../images/reference/fsivut/logofsi.png"
import LogoHelcel from "../images/reference/helcel/logohelcel.png"
import LogoIFE from "../images/reference/ife/logoife.png"
import LogoJena from "../images/reference/jena/logojena.png"
import LogoKiwi from "../images/reference/kiwi/logo.png"
import LogoPlaneta from "../images/reference/planetaher/logo-planeta.png"
import LogoSZ from "../images/reference/snowboard-zezula/logo-sz.png"

const CertificatePanelBadge = styled(Col)`
  margin: 10px 0;
  justify-content: center;
  display: flex;

  @media (min-width: ${(props) => props.theme.sizes_min.sm}) {
    margin: 0;
  }
`

const RssBlogInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 5px;
  }

  p {
    flex-grow: 1;
  }
`

const RssBlogTitle = styled.div`
  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    min-height: 85px;
  }
`

const CertificatePanel = () => (
  <div className={"my-5"}>
    <TitleH2Center>Naše znalosti jsou prověřené</TitleH2Center>

    <Row className="align-items-center align-content-center justify-content-center">
      <CertificatePanelBadge sm={2} xs={6}>
        <a
          href="https://www.google.com/partners/agency?id=4359599149"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="img-fluid"
            src={BadgePartner}
            width="160"
            height="70"
            alt="Premier Google Partner"
          />
        </a>
      </CertificatePanelBadge>
      <CertificatePanelBadge sm={2} xs={6}>
        <img
          className="img-fluid"
          src={BadgeTrainer}
          width="160"
          height="53"
          alt="Google Partners Trainer"
        />
      </CertificatePanelBadge>
      <CertificatePanelBadge sm={2} xs={6}>
        <img className="img-fluid" src={HeurekaPartner} alt="Heureka Partner" />
      </CertificatePanelBadge>
      <CertificatePanelBadge sm={2} xs={6}>
        <a
          href="https://drive.google.com/file/d/1dVtOB6F4jG33DpTDyn6kYdnd5wj9L7rc/view"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="img-fluid"
            src={BadgeGuru}
            alt="AdWords guru 2018"
            width="190"
            height="70"
          />
        </a>
      </CertificatePanelBadge>
      <CertificatePanelBadge sm={2} xs={6}>
        <img
          className="img-fluid"
          src={BadgeSklik}
          alt="Sklik.cz a Zboží.cz ověření"
          width="230"
          height="70"
        />
      </CertificatePanelBadge>
    </Row>
  </div>
)

const ClaimWrapper = styled.div`
  padding: 80px 0 40px;
  text-align: center;

  p {
    font-size: 16px;
    margin-bottom: 2rem;
    @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
      font-size: 22px;
    }
  }
`

const Claim = ({ children }) => (
  <Container>
    <ClaimWrapper>
      <p>{children}</p>
    </ClaimWrapper>
  </Container>
)

export default function IndexPage({ data }) {
  const rss = data.allFeedIglooBlog.nodes
  const whatWeOffer = [
    {
      image: <IconResponsive width={116} height={116} />,
      title: "Web nebo e-shop",
      href: "/vyvoj",
      text: "Vytvoříme úplně nový, nebo jen vylepšíme ten stávající. Stavíme při tom na našich znalostech, vašich zkušenostech a tvrdých datech z uživatelských výzkumů a testování.",
    },
    {
      image: <IconNetwork width={116} height={116} />,
      title: "Online marketing",
      href: "/marketing",
      text: (
        <>
          Kdo chce okamžitý výkon, tomu nastartujeme PPC, zbožáky
          i&nbsp;Facebook reklamu. Kdo vytrvá, s tím se pustíme do marketingové
          strategie, e‑mailingu i SEO. Buď vše zařídíme my, nebo jako mentoři
          povedeme vaše lidi.
        </>
      ),
    },
    {
      image: <IconWebpage width={116} height={116} />,
      title: "Hosting",
      href: "/hosting",
      text: "Už nemusíte přemýšlet, zda pořídit virtuální, dedikovaný nebo cloudový server. My vám hosting přizpůsobíme tak, aby web či e-shop parádně šlapal – a abyste neplatili zbytečné peníze navíc.",
    },
  ]

  const listReferences = [
    {
      href: "/marketing/reference/kiwi",
      image: LogoKiwi,
      alt: "kiwi.com",
      name: "Kiwi.com",
    },
    {
      href: "/marketing/reference/fsivut",
      image: LogoFSIVUT,
      alt: "logo",
      name: "FSI VUT",
    },
    {
      href: "/marketing/reference/helcel",
      image: LogoHelcel,
      alt: "LogoHelcel",
      name: "HELCEL",
    },
    {
      href: "/marketing/reference/ife",
      image: LogoIFE,
      alt: "ref",
      name: "IFE-CR",
    },
    {
      href: "/marketing/reference/jena",
      image: LogoJena,
      alt: "jena.cz",
      name: "JENA",
    },
    {
      href: "/marketing/reference/snowboard-zezula",
      image: LogoSZ,
      alt: "snowboard-zezula.cz",
      name: "SNOWBOARD ZEZULA",
    },
    {
      href: "/vyvoj/reference/doktor-kladivo",
      image: LogoDoktorKladivo,
      alt: "Logo doktor-kladivo.cz",
      name: "DoktorKladivo.cz",
    },
    {
      href: "/vyvoj/reference/planeta",
      image: LogoPlaneta,
      alt: "Logo planetaher.cz",
      name: "Planetaher.cz",
    },
  ]

  return (
    <Layout>
      <BannerHomepage>
        <h1>Jediná firma,</h1>
        <p>kterou budete v on-line světě potřebovat</p>
      </BannerHomepage>
      <Claim>
        Nejsme továrna, pracujeme raději pro menší množství klientů pořádně.
        Nebudeme se na vás učit, naši specialisté mají průměrně 6 let zkušeností
        v oboru.
      </Claim>

      <TextBoxImageTop title="Co pro vás zařídíme" data={whatWeOffer} />

      <Container>
        <CertificatePanel />

        <PaddingWrapper>
          <TitleH2Center>Můžete se na nás spolehnout</TitleH2Center>
          <ReferencesFull anotherReferences={listReferences} />
          <div className="d-flex justify-content-center mt-3">
            <Button to="/reference" style={ButtonStyle.Primary}>
              Další reference
            </Button>
          </div>
        </PaddingWrapper>

        <PaddingWrapper>
          <TitleH2Center>Zůstaňte s námi v obraze</TitleH2Center>
          <Row>
            {rss.slice(0, 3).map((article, index) => (
              <Col key={index} lg={4} className="mb-3">
                <RssBlogInner>
                  <RssBlogTitle>
                    <h3>
                      <Link to={article.link}>{article.title}</Link>
                    </h3>
                    <div className="mb-1">
                      <small>Autor: {article.creator}</small>
                    </div>
                  </RssBlogTitle>
                  <p>
                    {article.content
                      .substr(0, article.content.indexOf("</p>"))
                      .replace(/<\/?[^>]+(>|$)/g, "")
                      .replace(/&#160;/g, " ")}
                  </p>
                </RssBlogInner>
              </Col>
            ))}
          </Row>
        </PaddingWrapper>
      </Container>
    </Layout>
  )
}

// excluded
// supports
// suitable

export const query = graphql`
  query BlogRSSQuery {
    allFeedIglooBlog {
      nodes {
        title
        link
        content
        creator
      }
    }
  }
`
